'use strict'



routes.menu = function(id, action) {


    mount('loading');
         recipeService.fetchAllRecipes()
        .then(function(recipes) {
          var allRecipies={
            mains:[],
            starters:[],
            sides:[],
            salads:[],
            combos:[],
            add_ons:[],
            onam:[]

          }
       var allRecipiesDict={
            mains:{},
            starters:{},
            salads:{},
            sides:{},
            combos:{},
            add_ons:{},
            onam:{}

          }

          var recipe_count=Object.keys(recipes).length;
          var urlItem={};
          var foundItem=false;
             for (var key in recipes ){
               allRecipies[recipes[key].category].push(recipes[key]);
               allRecipiesDict[recipes[key].category][key]=recipes[key];

               if (id){
                 if(recipes[key].recipe_id==id){
                   urlItem=recipes[key];
                      foundItem=true;
                 }
               }
             }


try {
           for(var cat in global_categories){
       allRecipies[global_categories[cat]].sort(function(a,b){
                        return a['menu_order']-b['menu_order'];
                    });


       }
}
catch(err) {
 console.log(err);
}


           
     

if (id){
               if(!foundItem){
                 mount('not-found-page')
               }
               else{
                var recipe_modal=document.querySelector('#recipe-detail-modal');
                  selectedRecipeObs.trigger('passRecipeDetailData', urlItem);
                if(!recipe_modal.open)
                {
                $('.sticky').removeClass('fixed');
                 $('#recipe-detail-modal').scrollTop(0);
                 recipe_modal.showModal();

                }
               }

           }


            mount('menu', { recipes: allRecipies,length:recipe_count,recipes_dict:allRecipiesDict});
         });


}
